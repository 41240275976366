import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  })
};
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  signIncallBack() {
    throw new Error('Method not implemented.');
  }
  urlLogin: string

  constructor(private http: HttpClient) {
    this.urlLogin = environment.urlLogin

  }

  send(credentials) {
    return this.http.post<any>(this.urlLogin, credentials);
  }
}
