import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UniversalAppInterceptor } from './pages/pages/auth/UniversalAppInterceptor.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VexModule } from '../@vex/vex.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { CustomLayoutModule } from './custom-layout/custom-layout.module';
import { XmlInIntegrateursComponent } from './pages/apps/xml-in-integrateurs/xml-in-integrateurs.component';
import { MappageXmlComponent } from './pages/apps/mappage-xml/mappage-xml.component';
import { XmlOutComponent } from './pages/apps/xml-out/xml-out.component';
import { UsersComponent } from './pages/apps/users/users.component';
import { GroupesComponent } from './pages/apps/groupes/groupes.component';
import { DashboardComponent } from './pages/apps/dashboard/dashboard.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfirmRegistrationComponent } from './pages/pages/auth/confirm-registration/confirm-registration.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { PageLayoutModule } from 'src/@vex/components/page-layout/page-layout.module';
import { RoomComponent } from './pages/apps/room/room.component';
import { MatIconModule } from '@angular/material/icon';
import { BoardComponent } from './pages/apps/board/board.component';
import { VillesComponent } from './pages/apps/villes/villes.component';
import { CountryComponent } from './pages/apps/country/country.component';
import { RecuComponent } from './pages/apps/recu/recu.component';
import { CrmComponent } from './pages/apps/crm/crm.component';
import { ChildPriceComponent } from './pages/apps/child-price/child-price.component';
import { AvanceComponent } from './pages/apps/avance/avance.component';
import { AvanceFournisseurComponent } from './pages/apps/avance-fournisseur/avance-fournisseur.component';
import { CautionComponent } from './pages/apps/caution/caution.component';
import { CautionFournisseurComponent } from './pages/apps/caution-fournisseur/caution-fournisseur.component';
import { EnTeteComponent } from './pages/apps/en-tete/en-tete.component';
import { InscriptionComponent } from './pages/apps/inscription/inscription.component';
import { ConferencierComponent } from './pages/apps/conferencier/conferencier.component';
import { PresenceComponent } from './pages/apps/presence/presence.component';
import { PresencesComponent } from './pages/apps/presences/presences.component';




// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
  /* for development
  return new TranslateHttpLoader(
      http,
      '/start-angular/SB-Admin-BS4-Angular-6/master/dist/assets/i18n/',
      '.json'
  ); */
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};
@NgModule({
  declarations: [AppComponent, ConfirmRegistrationComponent, CrmComponent],
  exports:[],
  schemas:[CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSlideToggleModule,
    

    


    // Vex
    VexModule,
    CustomLayoutModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
      }
  }),
  
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: UniversalAppInterceptor, multi: true }, ],
  bootstrap: [AppComponent]
})
export class AppModule { }
