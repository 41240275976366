import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SearchingComponent } from './searching.component';
@Injectable({
  providedIn: 'root'
})
export class SearchingSendDataService {
  private data = new BehaviorSubject<any>({});
  private ResultSearch = new BehaviorSubject<any>({});
  private FiltredResult  = new BehaviorSubject<any>({});
  private txtcontent = new BehaviorSubject<any>('1 chambre, 2 adultes');
  private objetreserv = new BehaviorSubject<any>({});
  currentData = this.data.asObservable();
  currenttxtcontent = this.txtcontent.asObservable()
  objetreservation=this.objetreserv.asObservable()
  ResultaSearch=this.ResultSearch.asObservable()
  FiltredResultSearch= this.FiltredResult.asObservable()
  constructor( ) {

  }
  SendReservationDetails(objreserv){
    this.objetreserv.next(objreserv)
  }
  updatetxtcontent(txtcontent) {
    this.txtcontent.next(txtcontent)
  }
  updatedata(data: any) {
    this.data.next(data)
  }
  updateresult(data: any) {
    this.ResultSearch.next(data)
  }
  updateFiltredResult(data : any){
    this.FiltredResult.next(data)
  }

}