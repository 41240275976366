// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 
  urlstaticlist: '/list',
  //urlLogin: '/Login',
  urlRegister: '/RegisterClient',
  urlResetRequest: '/ResetPasswordRequest',
  urlUpdatePassword: '/ResetPassword',
  urlUpdateMappage: '/Mappage',
  urlSelectMappage: '/Mappageselect',
  urlReverseSelectMappage: '/Mappagereverseselect',
  urlListCountry: '/listcountry',
  urlConfirmRegistration: "/ConfirmRegistration",
  urlXmlout: '/XmlOut',
  urlAddAgency: '/AddAgency',
  urlUpdateAgencyInProd: '/UpdateAgencyInProd',
  urlUpdateAgencyStatus: '/UpdateAgencyStatus',
  urlGroups: '/Group',
  urlGroupChange: '/GroupChange',
  urlAgenceBygroup: '/AgenceByGroup',
  urlAdd_hotel: '/Hotel',
  urlget_hotel: '/Hotel',
  urlPut_hotel: '/Hotel',
  urlDelete_hotel: '/Hotel',
  urlGet_city: '/City',
  urlGet_country: '/Country',
  urlGet_facilitie: '/Facility',
  urlPost_facilitie: '/Facility',
  urlPut_facilitie: '/Facility',
  urlDelete_facilitie: '/Facility',
  urlPost_facilitie_id: '/AssignFacility',
  urlDashboard: '/Dashboard?clientid=21',
  urlGetListGroupe: '/Group',
  urlGetAgences: '/XmlOut',
  urlFileUpload: '/FileUpload',
  urllogos:'/logo',
  urlUser:"/User",
  urlGet_image:'/HotelImage/',
  urlPost_image:'/HotelImage',
  urlDelete_image:'/HotelImage/',
  urlupdatestatusUser:"/UserActivate",
  urlGet_hotel:'/Hotel',
  urlGet_Contart:'/Contrat',
  urlGet_RoomType:'/RoomType',
  url_post_contrat:'/Contrat',
  urlGet_Room:'/Room', /*ines@dotcom.tn Form array Period */
  url_post_room_contrat:'/RoomContrat', /*rb@dotcom.tn  api post room contrat*/
  url_get_arrangement:'/Arrangement',
  url_post_board_contrat:'/BoardContrat',
  url_post_periode_contrat:'/Period',
  url_Get_board:'/Arrangement',
  url_post_board:'/Arrangement/',
  url_put_board:'/Arrangement/',
  url_delete_board:'/Arrangement/',
  url_Get_country:'/Country',
  url_Post_country:'/Country',
  url_delete_country:'/Country/',
  url_Put_country:'/Country/',
  url_put_room:'/Room/',
  url_delete_room:'/Room/',
  url_post_room:'/Room/',
  urlGet_ville:'/City',
  urlpost_ville:'/City',
  url_put_ville:'/City/',
  url_delete_ville:'/City/',
  urlPostBaseRoomPrice: '/BasePrice',
  url_Get_Period: '/Period/',
  url_Get_Room: '/Room/',
  url_Get_RoomType: '/RoomType/',
  urlGet_board: '/Arrangement/',
  url_Get_Periode_Of_Contrat: '/PeriodParContrat',
  url_Get_Boards_Of_Contrat: '/BoardContratParContrat',
  url_Get_Room_Of_Contart: '/RoomContratParContrat',
  urlGet_ContartAutoComplete: '/ContratAutoComplete',
  url_Post_ChildPrice:'/ChildPrice',
  url_get_ChildPrice:'/ChildPrice/',
  url_Put_ChildPrices:'/ChildPrice/',
  url_Delete_ChildPrices:'/ChildPrice/',
  url_get_contrat:'/Contrat',
  url_Post_SpecialOffer:'/SpecialOffer',
  url_get_ChaineHotel:'/HotelChain',
  url_Post_booking:'/EarlyBooking',
  url_Post_DayPromotion:'/DayPromotion',
  url_Post_stay:'/LongStay',
  url_Post_HonneyMoon:'/HoneyMoon',
  url_Post_RoomPromotion:'/RoomPromotion',
  url_Post_PaxPromotion:'/PaxPromotion',
  url_Get_MinimumStay:'/MinimumStayTab/',
  url_Post_MinimumStay:'/MinimumStay',
  url_Get_WeekendDay:'/CalculateAll/',
  url_Post_weekendDay:'/WeekendDay',
  url_Post_StopSale:'/StopSale',
  url_Get_StopSale:'/StopSale',
  url_Post_Sale:'/StopSale',
  url_Get_ChildPrice_Of_Contart:'/ChildPrice/Contrat',
  url_Get_SpecialOffer_Of_Contart:'/SpecialOffer/Contrat',
  url_Get_EarlyBooking_Of_Contart:'/EarlyBooking/Offer',
  url_DayPromotion_Of_Contart:'/DayPromotion/Offer',
  url_LongStay_Of_Contart:'/LongStay/Offer',
  url_Get_HoneyMoonersSpo_Of_Contart:'/HoneyMoon/Offer',
  url_Get_RoomPromotion_Of_Contrat:'/RoomPromotion/Offer',
  url_Get_PaxPromotion_Of_Contrat:'/PaxPromotion/Offer',
  url_Get_WeekendDay_Of_Contrat:'/WeekendDay',
  url_Get_MinimumStay_Of_Contrat:'/MinimumStay/Offer',
  url_Get_StopSale_Of_Contrat:'/StopSale/Offer',
  url_Get_EarlyBooking:'/EarlyBooking',
  urlXmlin:'',
  urlmappagelist:'',
  urlReservations:'',
  urlAutoComplete:'',
  urlSession:'',
  urlSearch:'',
  urlTestXmlin:'',



/****************************************************************** */
  url_Get_Reservation:'https://events.dotcom.tn/events/Reservation_1003',
  url_Post_Reservation:'https://events.dotcom.tn/events/Reservation_1003',
  url_Get_Recu_Paiement:'https://events.dotcom.tn/events/Virement/',
  url_Priseencharge:'https://events.dotcom.tn/events/PriseEnCharge/',
  url_Resume:'https://events.dotcom.tn/events/Resume/',
  url_Poster:'https://events.dotcom.tn/events/Poster/',
  url_Confirmer_Reservation:'https://events.dotcom.tn/events/Confirmation/',
  url_Get_Voucher:'https://events.dotcom.tn/events/Voucher/AMOP_voucher_',
  url_Get_Badge:'https://events.dotcom.tn/events/Badge/AMOP_badge_',
  urlLogin: 'https://events.dotcom.tn/events/Login',
  url_Annuler_Reservation:"https://events.dotcom.tn/events/Annulation/",
  url_refus_conferencier:"https://events.dotcom.tn/events/RefusResume/",
  url_getlist_conferencier:'https://events.dotcom.tn/events/Submission',
  url_confirm_conferencier:'https://events.dotcom.tn/events/ConfirmResume/',
  url_supprimer_reservation:'https://events.dotcom.tn/events/SupprimerReservation_1003/',
  url_update_presence:'https://events.dotcom.tn/events/PresenceParBadge_1003/',
  url_list_presence:'https://events.dotcom.tn/events/Presence_1003/',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
