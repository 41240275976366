<div *ngIf="config$ | async as setting" class="config-panel">
  <h2 class="headline mb-4">

    <span>Filtre</span>
    
  </h2>

  <div *ngIf="FilterType=='dashboard'" @fadeInUp class="card flex-auto">
    <div class="px-6 py-4 border-b flex items-center">
      <h2 class="title m-0">Affichage</h2>
    </div>
    <mat-button-toggle-group style="margin-left: 13%;" [formControl]="layoutCtrl" class="mt-4 sm:mt-0">
      <mat-button-toggle (change)="onValChange($event.value)" value="Annuel">Annuel</mat-button-toggle>
      <mat-button-toggle (change)="onValChange($event.value)" value="Mensuel">Mensuel</mat-button-toggle>
    </mat-button-toggle-group>
    <div class="px-6 py-4 border-b flex items-center">
      <h2 class="title m-0">Filtrer par date</h2>
    </div>
   
    <div class="px-6 py-4 flex flex-col">
      <mat-form-field>
        <mat-label>From Date</mat-label>
        <input (click)="formDatepickerRef.open()" (focus)="formDatepickerRef.open()" [matDatepicker]="formDatepickerRef"
          matInput (dateChange)="changeFromDate($event)" readonly>
        <mat-datepicker-toggle [for]="formDatepickerRef" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #formDatepickerRef></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>To Date</mat-label>
        <input (click)="formDatepickerRef1.open()" (focus)="formDatepickerRef1.open()"
          [matDatepicker]="formDatepickerRef1" matInput (dateChange)="changeToDate($event)" readonly>
        <mat-datepicker-toggle [for]="formDatepickerRef1" matSuffix></mat-datepicker-toggle>
        <mat-datepicker #formDatepickerRef1></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="FilterType=='dashboard'" @fadeInUp class="card flex-auto">
    <div class="px-6 py-4 border-b flex items-center">
      <h2 class="title m-0">Filtrer Destination</h2>
    </div>


    <div class="px-6 py-4 flex flex-col">
      <mat-form-field>
        <mat-label>Countries</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" [formControl]="staticListCtrl"
          (click)="$event.stopPropagation()">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onChangeDestination($event.option.value)">
          <mat-option *ngFor="let state of filteredStaticList$| async" [value]="state.name">
            <span class="body-1">{{ state.name }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>


      <mat-form-field>
        <mat-label>Cities</mat-label>
        <input type="text" matInput [matAutocomplete]="auto1" [formControl]="CitiesListCtrl"
          (click)="$event.stopPropagation()">
        <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onChangecity($event.option.value)">
          <mat-option *ngFor="let city of filteredCitiesList$| async" [value]="city.name">
            <span class="body-1">{{ city.name }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>


      <mat-form-field>
        <mat-label>Hotels</mat-label>
        <input type="text" matInput [matAutocomplete]="auto2" [formControl]="HotelsListCtrl"
          (click)="$event.stopPropagation()">
        <mat-autocomplete #auto2="matAutocomplete" (optionSelected)="onChangehotel($event.option.value)">
          <mat-option *ngFor="let hotel of filteredHotelsList$| async" [value]="hotel.name">
            <span class="body-1">{{ hotel.name }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <div *ngIf="FilterType=='dashboard'" @fadeInUp class="card flex-auto">
    <div class="px-6 py-4 border-b flex items-center">
      <h2 class="title m-0">Filtrer Destination</h2>
    </div>
    <div class="px-6 py-4 flex flex-col">
      <mat-form-field>
        <mat-label>Groupe</mat-label>
        <input type="text" matInput [matAutocomplete]="auto3" [formControl]="GroupesListCtrl"
          (click)="$event.stopPropagation()">
        <mat-autocomplete #auto3="matAutocomplete" (optionSelected)="onChangegroupe($event.option.value)">
          <mat-option *ngFor="let groupe of filteredGroupeList$| async" [value]="groupe.name">
            <span class="body-1">{{ groupe.name }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Agences</mat-label>
        <input type="text" matInput [matAutocomplete]="auto4" [formControl]="AgencesListCtrl"
          (click)="$event.stopPropagation()">
        <mat-autocomplete #auto4="matAutocomplete" (optionSelected)="onChangeagence($event.option.value)">
          <mat-option *ngFor="let agence of filteredAgencesList$| async" [value]="agence.name">
            <span class="body-1">{{ agence.name }}</span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>

  </div>
  <div *ngIf="FilterType=='searching'" @fadeInUp>




    <h4 style="font-family: 'Roboto Condensed',sans-serif;font-weight: 700;line-height: 1.5;">Filtrer par nom</h4>
    <mat-form-field>
      <mat-label>Mot clès</mat-label>
      <input type="text" matInput id="keyword" (keyup)="onEnter($event)">
    </mat-form-field>
    <h4 style="font-family: 'Roboto Condensed',sans-serif;font-weight: 700;line-height: 1.5;">Filtrer par Budget</h4>
    <div class="px-6 py-4 flex flex-col">
      <div *ngIf="ResultSearch.source.value.HotelsSearch != undefined" class="flex flex-col gap-2">
        <mat-slider [max]="ResultSearch.source.value.HotelsSearch.Stats.HighestPrice"
          [min]="ResultSearch.source.value.HotelsSearch.Stats.LowestPrice" [step]="1" [thumbLabel]="true"
          [tickInterval]="10" value="ResultSearch.source.value.HotelsSearch.Stats.HighestPrice/2"></mat-slider>
      </div>
    </div>
    <div *ngIf="ResultSearch.source.value.HotelsSearch != undefined">
      <h4 style="font-family: 'Roboto Condensed',sans-serif;font-weight: 700;line-height: 1.5;">Filtrer par Catégories
      </h4>

      <div *ngFor="let star of ResultSearch.source.value.HotelsSearch.Stats.ListStars; let i=index"
        class="px-6 py-4 flex flex-col">
        <mat-checkbox (change)="FiltreRating($event,star)" [checked]="false" align="end"><span
            *ngFor="let n of [].constructor(star)">
            <mat-icon svgIcon="mat:star" class="icon-sm rating"></mat-icon>
          </span></mat-checkbox>

      </div>
    </div>
    <div *ngIf="ResultSearch.source.value.HotelsSearch != undefined">
      <h4 style="font-family: 'Roboto Condensed',sans-serif;font-weight: 700;line-height: 1.5;">Filtrer par Arrangements
      </h4>

      <div *ngFor="let boarding of ResultSearch.source.value.HotelsSearch.Stats.ListBoarding; let i=index"
        class="px-6 py-4 flex flex-col">
        <mat-checkbox (change)="FiltreBoarding($event,boarding)" [checked]="false" align="end">
          {{boarding}}
        </mat-checkbox>

      </div>
    </div>




  </div>
</div>